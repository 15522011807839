import request from '@/utils/request'


// 查询客服、小助手列表
export function listService(query) {
  return request({
    url: '/customer/service/list',
    method: 'get',
    params: query
  })
}

// 查询客服、小助手分页
export function pageService(query) {
  return request({
    url: '/customer/service/page',
    method: 'get',
    params: query
  })
}

// 查询客服、小助手详细
export function getService(data) {
  return request({
    url: '/customer/service/detail',
    method: 'get',
    params: data
  })
}

// 新增客服、小助手
export function addService(data) {
  return request({
    url: '/customer/service/add',
    method: 'post',
    data: data
  })
}

// 修改客服、小助手
export function updateService(data) {
  return request({
    url: '/customer/service/edit',
    method: 'post',
    data: data
  })
}

// 删除客服、小助手
export function delService(data) {
  return request({
    url: '/customer/service/delete',
    method: 'post',
    data: data
  })
}
