<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="类型" prop="type" >
        <a-radio-group v-model="form.type">
          <a-radio :value="1">小助手</a-radio>
          <a-radio :value="2">平台客服</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="申请类型" prop="assistantType" v-if="form.type == 1">
        <a-radio-group   v-model="form.assistantType">
          <a-radio :value="1">申根国内</a-radio>
          <a-radio :value="2">申根英国</a-radio>
          <a-radio :value="3">美签国内</a-radio>
          <a-radio :value="4">美签英国</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="二维码" prop="image" >
        <file-upload type="image" :defaultList="form.imageArr" :count="1"
                     @input="getImg($event, 'image')"></file-upload>
        <span style="color: red">建议上传图片尺寸：320 x 320 px</span>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getService, addService, updateService } from '@/api/customer/service'
import {guid} from "@/utils/ruoyi";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        type: null,

        image: null,
        imageArr:[],
        imageCount:1,
        assistantType: null,
        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: '类型不能为空', trigger: 'change' }
        ],
        assistantType: [
          { required: true, message: '小助手类型不能为空', trigger: 'change' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },

    /**上传图片成功回调 */
    getImg(info, fieldName) {
      console.log('info', info)
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].url
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info.map(ele => { return ele.url }).join(",")
          this.form[`${fieldName}Arr`] = info
        }
      }
    },


    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        image: null,
        assistantType: null,
        imageArr:[],
        imageCount:1,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },

    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getService({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.form.imageArr=[]
        if(this.form.image){
          let that = this
          this.form.imageArr.push({
            uid: guid(8, 10),
            name: that.form.image,
            status: 'done',
            halfUrl: that.form.image,
            url: that.form.image,
            path: that.form.image
          })
        }
      })
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateService(this.form).then(res => {
              if(res.success){
                this.$message.success(
                  '修改成功',
                  3
                )
                this.open = false
                this.$emit('ok')
              }else{
                this.$message.error(res.message)
              }
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addService(this.form).then(res => {
              if(res.success){
                this.$message.success(
                  '新增成功',
                  3
                )
                this.open = false
                this.$emit('ok')
              }else{
                this.$message.error(res.message)
              }
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
